<template>
  <div>
    <div>
      <img class="top_image" :src="menu.bannerImg" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size:16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size:16px">新闻资讯</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <div class="fgx">
      <p class="title"><span>新闻资讯</span></p>
      <div class="line">
        <p class="linebg"></p>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <!-- 新闻资讯详情 -->
      <div class="newscontent">
        <div
            class="news_content"
            v-for="item in synewslist"
            :key="item.id"
            @click="news(item.id)"
            style="cursor:pointer"
        >
          <div class="mdimgcontent">
            <img
                :src="item.bannerImg"
                alt=""
                style=" width: 358px;height:178px"
            />
            <p class="dw">
              <span class="ywx_content_line1" style="padding-left: 12px">{{ item.title }}</span>
            </p>
          </div>
          <p style="
                margin-top: 12px;
                padding-left: 12px;
                color: #000000;
                font-size: 14px;
                text-align: left;

              " class="bews_line">{{ item.subtitle }}</p>
        </div>

        <div style="text-align:center">
          <p class="jiazaimes">{{ stauts }}</p>
          <el-button round class="downbtn" @click="addcount"
          >{{ moreLabel }}
          </el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getContentList, getMenuList
} from "../request/index";

export default {
  data() {
    return {
      synewslist: [],
      stauts: "",
      count: 0,
      size: 6,
      bread: "",
      pageNum: 0,
      moreLabel: '更多资讯',
      menu: {
        id:'',
        bannerImg:''
      }
    };
  },
  created() {
    getMenuList({path:this.$route.path}).then(res => {
      this.menu = res.data[0];
      this.getNewsList();
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {},
  methods: {
    getNewsList() {
      this.pageNum++;
      let params = {
        pageNum: this.pageNum,
        pageSize: 12,
        menuId: this.menu.id
      }
      getContentList(params).then(res => {
        if (res.rows.length > 0) {
          this.synewslist = this.synewslist.concat(res.rows);
        } else {
          this.moreLabel = "没有更多了~";
        }
      });
    },
    //加载更多
    addcount() {
      this.getNewsList(this.menu.id);
    },
    //获取新闻详情
    news(key) {
      this.$router.push({
        path: "/news/detail",
        query: {
          newsId: key
        }
      });
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: center;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bews_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top_image {
  width: 100%;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
}

.fgx .title {
  font-weight: 900;
  text-align: left;
  margin-top: 30px;
  font-size: 20px;
  margin-bottom: 10px;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #efefef;
}

.line .linebg {
  height: 5px;
  width: 80px;
  background-color: black;
}

.http {
  width: 700px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  height: 30px;
  margin-bottom: 100px;
  line-height: 30px;
}

.bigline {
  height: 1px;
  width: 1200px;
  margin: 10px auto;
  background-color: #dddddd;
}

.news .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 30px;
  text-align: left;
  padding-bottom: 50px;
  text-justify: inter-word;
  background: url("../../static/切图文件/bg-map-1.png") no-repeat top center;
}

.news .newscontent .news_content {
  display: inline-block;
  width: 358px;
  height: 240px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 30px;
  vertical-align: top;
}

.news .newscontent .news_content .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.news .newscontent .news_content .dw {
  margin: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.news .newscontent .news_content:hover {
  box-shadow: 0 0 20px #aaa;
}

.downbtn {
  margin-top: 30px;
  height: 40px;
  text-align: center;
  /* transform: translate(-52px); */
}

.jiazaimes {
  font-size: 18px;
  color: rgb(249, 145, 0);
}
</style>